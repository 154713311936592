.color-blue[data-v-039c5b43] {
  color: #033d4f !important;
}
.color-orange[data-v-039c5b43] {
  color: #f87e2b !important;
}
.color-gray[data-v-039c5b43] {
  color: #404040 !important;
}
.color-white[data-v-039c5b43] {
  color: white;
}
.bg-orange[data-v-039c5b43] {
  background-color: #f87e2b;
}
.bg-gray[data-v-039c5b43] {
  background-color: #A2A2A2;
}
.font-bold[data-v-039c5b43] {
  font-weight: 700;
}
.list-style-type-none[data-v-039c5b43] {
  list-style-type: none;
}
.line-height-1[data-v-039c5b43] {
  line-height: 1;
}
.line-height-1-5[data-v-039c5b43] {
  line-height: 1.5;
}
.line-height-2[data-v-039c5b43] {
  line-height: 2;
}
.font-weight-bold[data-v-039c5b43] {
  font-weight: 700;
}
.font-weight-light[data-v-039c5b43] {
  font-weight: 400;
}
.font-size-small[data-v-039c5b43] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-039c5b43] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-039c5b43] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-039c5b43] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-039c5b43] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-medium[data-v-039c5b43] {
    font-size: 16px;
}
.font-size-big[data-v-039c5b43] {
    font-size: 21px;
}
.font-size-large[data-v-039c5b43] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-medium[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-big[data-v-039c5b43] {
    font-size: 16px;
}
.font-size-large[data-v-039c5b43] {
    font-size: 21px;
}
}
.process-structure[data-v-039c5b43] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 125px 0 175px 0;
}
.process-structure .auth-process-body[data-v-039c5b43] {
  width: 95vw;
  max-width: 600px;
}
.process-structure .apply-process-body[data-v-039c5b43] {
  width: 95vw;
  max-width: 1280px;
  padding: 0 50px;
}
.auth-form-block[data-v-039c5b43] {
  width: 100%;
  max-width: 600px;
}
.title-tips[data-v-039c5b43] {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #404040;
  text-align: center;
}
.submit-btn[data-v-039c5b43] {
  width: 100%;
  color: white;
  background-color: #f87e2b;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.disable-btn[data-v-039c5b43] {
  width: 100%;
  background-color: #808080;
  color: white;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: not-allowed;
}
.v-stepper[data-v-039c5b43] {
  box-shadow: none !important;
}
.v-stepper__label[data-v-039c5b43] {
  text-align: center !important;
  line-height: 1.2;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label[data-v-039c5b43] {
  text-shadow: 0px 0px 0px #f87e2b !important;
  color: #f87e2b !important;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label[data-v-039c5b43] {
  text-shadow: 0px 0px 0px #f87e2b;
  color: #f87e2b !important;
}
.stepper[data-v-039c5b43] {
  margin-bottom: 50px;
}
@media only screen and (max-width: 960px) {
.v-stepper:not(.v-stepper--vertical) .v-stepper__label[data-v-039c5b43] {
    display: block !important;
}
}
@media only screen and (max-width: 600px) {
.process-structure[data-v-039c5b43] {
    padding: 40px 0 100px 0;
}
.v-stepper__header[data-v-039c5b43] {
    justify-content: center !important;
    box-shadow: none !important;
}
.v-stepper__header .v-stepper__step[data-v-039c5b43] {
    padding-bottom: 0;
}
.v-stepper__header .v-divider[data-v-039c5b43] {
    display: none;
}
}
.border-orange-input fieldset[data-v-039c5b43] {
  border: 1px solid #f87e2b !important;
}
.border-orange-input.v-input--is-focused fieldset[data-v-039c5b43] {
  border: 2px solid #f87e2b !important;
}
.v-text-field.v-text-field--enclosed[data-v-039c5b43] {
  margin-bottom: 15px !important;
}
.white-btn[data-v-039c5b43] {
  border: 1px solid #f87e2b;
  background-color: white;
  color: #f87e2b;
}
.blue-btn[data-v-039c5b43] {
  color: white;
  background-color: #033d4f;
}
.orange-btn[data-v-039c5b43] {
  color: white;
  background-color: #f87e2b;
}
.popup[data-v-039c5b43] {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
}
.popup .mask[data-v-039c5b43] {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.popup .panel[data-v-039c5b43] {
  position: absolute;
  z-index: 501;
  background-color: white;
  width: 70vw;
  box-shadow: 2.56036px 2.56036px 17.0691px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 15vh;
  left: 50vw;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.popup .panel .close-icon[data-v-039c5b43] {
  position: absolute;
  top: 27px;
  right: 35px;
  color: #f87e2b;
  font-size: 32px;
  cursor: pointer;
}
.popup .panel .checkbox-box[data-v-039c5b43] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions[data-v-039c5b43] {
  display: flex;
  justify-content: center;
}
.popup .panel .actions .btn[data-v-039c5b43] {
  width: 160px;
  height: 53px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.popup .panel .actions .btn[data-v-039c5b43]:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
.popup .panel[data-v-039c5b43] {
    width: 95vw;
}
.popup .panel .actions .btn[data-v-039c5b43] {
    width: 140px;
    height: 48px;
    font-size: 14px;
}
}
html[data-v-039c5b43] {
  scroll-behavior: smooth !important;
}
.panel-item[data-v-039c5b43] {
  border: 1px solid #f87e2b;
  border-bottom: 0px;
}
.panel-item[data-v-039c5b43]:last-child {
  border-bottom: 1px solid #f87e2b;
}
.column-center[data-v-039c5b43] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-overlay[data-v-039c5b43] {
  z-index: 99999 !important;
}
.page[data-v-039c5b43] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.web-content[data-v-039c5b43] {
  width: 100vw;
  max-width: 1440px;
}
.page-center[data-v-039c5b43] {
  display: flex;
  justify-content: center;
}
.page-banner[data-v-039c5b43] {
  margin-bottom: 80px;
}
.page-banner img[data-v-039c5b43] {
  width: 100vw;
}
.desktop-banner[data-v-039c5b43] {
  display: block;
}
.mobile-banner[data-v-039c5b43] {
  display: none;
}
.page-structure[data-v-039c5b43] {
  display: flex;
  justify-content: space-between;
  padding: 0 85px;
  width: 100%;
  position: relative;
}
.page-structure .page-content[data-v-039c5b43] {
  max-width: 1010px;
  width: calc(100% - 200px);
}
.normal-page .block[data-v-039c5b43] {
  width: 100%;
}
.normal-page .block[data-v-039c5b43]:not(:last-child) {
  margin-bottom: 50px;
}
.normal-page .block-title[data-v-039c5b43] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.normal-page .block-title .dot[data-v-039c5b43] {
  width: 10px;
  height: 10px;
  margin-right: 17px;
  background-color: #f87e2b;
}
.normal-page .block-sub-title[data-v-039c5b43] {
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
}
.normal-page .sub-block .sub-title[data-v-039c5b43] {
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.normal-page .sub-block .sub-title .title-tips[data-v-039c5b43] {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
}
.normal-page .sub-block[data-v-039c5b43]:not(:last-child) {
  margin-bottom: 30px;
}
.process-page-title-row[data-v-039c5b43] {
  display: flex;
  justify-content: center;
}
.process-page-title[data-v-039c5b43] {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #404040;
  padding-bottom: 12px;
  border-bottom: 3px solid #f87e2b;
  text-align: center;
}
@media screen and (max-width: 1024px) {
.page-structure .page-content[data-v-039c5b43] {
    width: 100%;
}
}
@media screen and (max-width: 960px) {
.desktop-banner[data-v-039c5b43] {
    display: none;
}
.page-structure[data-v-039c5b43] {
    padding: 0 30px;
}
.mobile-banner[data-v-039c5b43] {
    display: block;
}
.normal-page .block-title[data-v-039c5b43] {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
}
.normal-page .block-title .dot[data-v-039c5b43] {
    width: 5px;
    height: 5px;
    margin-right: 11px;
}
.normal-page .sub-block .sub-title[data-v-039c5b43] {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}
.normal-page .sub-block .sub-title .title-tips[data-v-039c5b43] {
    font-size: 12px;
}
.normal-page .sub-block[data-v-039c5b43]:not(:last-child) {
    margin-bottom: 30px;
}
}
.v-input[data-v-039c5b43] {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 600px) {
.page-banner[data-v-039c5b43] {
    margin-bottom: 30px;
}
.page-structure[data-v-039c5b43] {
    padding: 0 30px;
}
}
@media screen and (max-width: 400px) {
.page-structure[data-v-039c5b43] {
    padding: 0 15px;
}
}
.color-blue[data-v-039c5b43] {
  color: #033d4f !important;
}
.color-orange[data-v-039c5b43] {
  color: #f87e2b !important;
}
.color-gray[data-v-039c5b43] {
  color: #404040 !important;
}
.color-white[data-v-039c5b43] {
  color: white;
}
.bg-orange[data-v-039c5b43] {
  background-color: #f87e2b;
}
.bg-gray[data-v-039c5b43] {
  background-color: #A2A2A2;
}
.font-bold[data-v-039c5b43] {
  font-weight: 700;
}
.list-style-type-none[data-v-039c5b43] {
  list-style-type: none;
}
.line-height-1[data-v-039c5b43] {
  line-height: 1;
}
.line-height-1-5[data-v-039c5b43] {
  line-height: 1.5;
}
.line-height-2[data-v-039c5b43] {
  line-height: 2;
}
.font-weight-bold[data-v-039c5b43] {
  font-weight: 700;
}
.font-weight-light[data-v-039c5b43] {
  font-weight: 400;
}
.font-size-small[data-v-039c5b43] {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium[data-v-039c5b43] {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big[data-v-039c5b43] {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large[data-v-039c5b43] {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify[data-v-039c5b43] {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-medium[data-v-039c5b43] {
    font-size: 16px;
}
.font-size-big[data-v-039c5b43] {
    font-size: 21px;
}
.font-size-large[data-v-039c5b43] {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-medium[data-v-039c5b43] {
    font-size: 14px;
}
.font-size-big[data-v-039c5b43] {
    font-size: 16px;
}
.font-size-large[data-v-039c5b43] {
    font-size: 21px;
}
}
.about .is-mobile[data-v-039c5b43] {
  display: none;
}
.about .about-content[data-v-039c5b43] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about .about-content .block[data-v-039c5b43] {
  padding: 0 15px;
  width: 100%;
}
.about .about-content .words-box[data-v-039c5b43] {
  display: flex;
  margin-bottom: 50px;
}
.about .about-content .words-box .left-box[data-v-039c5b43] {
  border-left: 3px solid #f87e2b;
  padding: 5px 0 0 20px;
  margin-right: 85px;
}
.about .about-content .words-box .left-box .text[data-v-039c5b43] {
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #404040;
}
.about .about-content .words-box .image-box img[data-v-039c5b43] {
  width: 350px;
}
.about .about-content .words-box .image-box .text[data-v-039c5b43] {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}
.about .about-content .words-box .image-box .text .name[data-v-039c5b43] {
  color: #033d4f;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.1em;
  margin-right: 20px;
  line-height: 1;
}
.about .about-content .words-box .image-box .text .job-title[data-v-039c5b43] {
  color: #404040;
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  line-height: 1;
}
.about .about-content .video[data-v-039c5b43] {
  position: relative;
  padding-bottom: 50.54%;
  /* 16:9 */
  width: 100%;
  height: 0;
  margin-bottom: 50px;
}
.about .about-content .video .video-frame[data-v-039c5b43] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about .about-content .four-main[data-v-039c5b43] {
  margin-bottom: 50px;
}
.about .about-content .four-main .item[data-v-039c5b43] {
  display: flex;
  padding-bottom: 35px;
  border-bottom: 1px solid #f87e2b;
  align-items: flex-start;
}
.about .about-content .four-main .item img[data-v-039c5b43] {
  width: 66px;
  margin-right: 45px;
}
.about .about-content .four-main .item .text-box[data-v-039c5b43] {
  color: #404040;
}
.about .about-content .four-main .item .text-box .sub-title[data-v-039c5b43] {
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 25px;
}
.about .about-content .four-main .item .text-box .text[data-v-039c5b43] {
  font-weight: 700;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
}
.about .about-content .four-main .item[data-v-039c5b43]:not(:last-child) {
  margin-bottom: 50px;
}
.about .about-content .core .core-mobile[data-v-039c5b43] {
  display: none;
}
.about .about-content .core .core-desktop[data-v-039c5b43] {
  display: flex;
}
.about .about-content .core .core-content[data-v-039c5b43] {
  display: flex;
  justify-content: center;
}
.about .about-content .core .core-content .empty-item[data-v-039c5b43] {
  height: 69px;
}
.about .about-content .core .line img[data-v-039c5b43] {
  width: 60px;
}
.about .about-content .core .left[data-v-039c5b43] {
  padding-top: 40px;
  margin-right: 95px;
}
.about .about-content .core .left .item[data-v-039c5b43] {
  align-items: flex-end;
}
.about .about-content .core .right[data-v-039c5b43] {
  padding-top: 40px;
  margin-left: 95px;
}
.about .about-content .core .right .item[data-v-039c5b43] {
  align-items: flex-start;
}
.about .about-content .core .item[data-v-039c5b43] {
  display: flex;
  flex-direction: column;
  margin-bottom: 68px;
}
.about .about-content .core .item .item-title[data-v-039c5b43] {
  display: flex;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 0.2em;
  margin-bottom: 14px;
  line-height: 1;
}
.about .about-content .core .item .item-title .number[data-v-039c5b43] {
  margin-right: 20px;
}
.about .about-content .core .item .text[data-v-039c5b43] {
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  white-space: pre-line;
}
.about .about-content .core .item-1[data-v-039c5b43] {
  color: #626262;
}
.about .about-content .core .item-2[data-v-039c5b43] {
  color: #033d4f;
}
.about .about-content .partner[data-v-039c5b43] {
  margin-bottom: 70px;
}
.about .about-content .partner .partner-content[data-v-039c5b43] {
  padding-left: 50px;
}
.about .about-content .partner img[data-v-039c5b43] {
  flex-shrink: 0;
  width: 180px;
}
.about .about-content .partner .top[data-v-039c5b43] {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.about .about-content .partner .top .item[data-v-039c5b43] {
  display: flex;
  text-decoration: none;
  color: #404040;
  margin-right: 55px;
  line-height: 1;
}
.about .about-content .partner .top .item img[data-v-039c5b43] {
  margin-left: 5px;
}
.about .about-content .partner .top .item .label[data-v-039c5b43] {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #404040;
}
.about .about-content .partner .box[data-v-039c5b43] {
  display: flex;
  flex-wrap: wrap;
}
.about .about-content .partner .box .item[data-v-039c5b43] {
  margin-right: 45px;
  margin-bottom: 30px;
  display: block;
  line-height: 1;
}
@media screen and (max-width: 1280px) {
.about .about-content .words-box .image-box img[data-v-039c5b43] {
    width: 300px;
}
}
@media screen and (max-width: 1140px) {
.about .about-content .words-box .image-box img[data-v-039c5b43] {
    width: 260px;
}
}
@media screen and (max-width: 960px) {
.about .is-desktop[data-v-039c5b43] {
    display: none;
}
.about .is-mobile[data-v-039c5b43] {
    display: block;
}
.about .about-content .words-box[data-v-039c5b43] {
    flex-wrap: wrap-reverse;
    justify-content: center;
}
.about .about-content .words-box .image-box[data-v-039c5b43] {
    margin-bottom: 20px;
}
.about .about-content .words-box .left-box[data-v-039c5b43] {
    margin-right: 0;
}
.about .about-content .words-box .left-box .text[data-v-039c5b43] {
    font-size: 16px;
}
.about .about-content .four-main .item img[data-v-039c5b43] {
    flex-shrink: 0;
    flex-grow: 0;
}
.about .about-content .core .core-content .left[data-v-039c5b43] {
    margin-right: 60px;
}
.about .about-content .core .core-content .right[data-v-039c5b43] {
    margin-left: 60px;
}
.about .about-content .core .core-content .item .item-title[data-v-039c5b43] {
    font-size: 28px;
    line-height: 1;
}
.about .about-content .core .core-content .item .text[data-v-039c5b43] {
    font-size: 16px;
    line-height: 1;
}
.about .about-content .partner .partner-content[data-v-039c5b43] {
    padding-left: 0;
}
}
@media screen and (max-width: 600px) {
.about .about-content .words-box .image-box img[data-v-039c5b43] {
    width: 200px;
}
.about .about-content .words-box .image-box .text .name[data-v-039c5b43] {
    font-size: 21px;
}
.about .about-content .words-box .image-box .text .job-title[data-v-039c5b43] {
    font-size: 16px;
}
.about .about-content .words-box .left-box[data-v-039c5b43] {
    margin-right: 0;
}
.about .about-content .words-box .left-box .text[data-v-039c5b43] {
    font-size: 14px;
}
.about .about-content .four-main .item[data-v-039c5b43] {
    padding-bottom: 20px;
}
.about .about-content .four-main .item img[data-v-039c5b43] {
    width: 40px;
    margin-right: 18px;
}
.about .about-content .four-main .item .text-box .sub-title[data-v-039c5b43] {
    font-size: 16px;
    margin-bottom: 10px;
}
.about .about-content .four-main .item .text-box .text[data-v-039c5b43] {
    font-size: 14px;
}
.about .about-content .core .core-desktop[data-v-039c5b43] {
    display: none;
}
.about .about-content .core .core-mobile[data-v-039c5b43] {
    display: flex;
}
.about .about-content .core .core-mobile .mobile-content[data-v-039c5b43] {
    margin-right: 15px;
    margin-top: 30px;
}
.about .about-content .core .line img[data-v-039c5b43] {
    width: 45px;
}
.about .about-content .core .core-content .item[data-v-039c5b43] {
    margin-bottom: 50px;
}
.about .about-content .core .core-content .item .item-title[data-v-039c5b43] {
    font-size: 24px;
    justify-content: flex-end;
}
.about .about-content .core .core-content .item .text[data-v-039c5b43] {
    font-size: 14px;
    justify-content: flex-end;
}
.about .about-content .partner .partner-content .top[data-v-039c5b43] {
    margin-bottom: 20px;
}
.about .about-content .partner .partner-content .top .item[data-v-039c5b43] {
    margin-right: 0;
}
.about .about-content .partner .partner-content .top .item .label[data-v-039c5b43] {
    font-size: 14px;
}
.about .about-content .partner .partner-content .box .item[data-v-039c5b43] {
    margin-right: 20px;
}
.about .about-content .partner .partner-content .box img[data-v-039c5b43] {
    width: 130px;
}
}