:root {
	--delay: 0;
	--duration: 800ms;
	--iterations: 1;
}
.reveal-text,
  .reveal-text::after {
    -webkit-animation-delay: var(--animation-delay, 2s);
            animation-delay: var(--animation-delay, 2s);
    -webkit-animation-iteration-count: var(--iterations, 1);
            animation-iteration-count: var(--iterations, 1);
    -webkit-animation-duration: var(--duration, 800ms);
            animation-duration: var(--duration, 800ms);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.reveal-text {
    --animation-delay: var(--delay, 0);
    --animation-duration: var(--duration, 800ms);
    --animation-iterations: var(--iterations, 1);
    position: relative;
    font-size: 10vw;
    -webkit-animation-name: clip-text;
            animation-name: clip-text;
    color: #fff;
    white-space: nowrap;
    cursor: default;
}
.reveal-text::after {
    content: '';
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    pointer-events: none;
    -webkit-animation-name: text-revealer;
            animation-name: text-revealer;
}
.reveal-text-color1::after{
    background-color: #626262;
}
.reveal-text-color2::after{
    background-color: #033D4F;
}
.reveal-text-color3::after{
    background-color: #038499;
}
.reveal-text-color4::after{
    background-color: #F97E2B;
}
.reveal-text-color5::after{
    background-color: #C5981B;
}
@-webkit-keyframes clip-text {
from {
      -webkit-clip-path: inset(0 100% 0 0);
              clip-path: inset(0 100% 0 0);
}
to {
      -webkit-clip-path: inset(0 0 0 0);
              clip-path: inset(0 0 0 0);
}
}
@keyframes clip-text {
from {
      -webkit-clip-path: inset(0 100% 0 0);
              clip-path: inset(0 100% 0 0);
}
to {
      -webkit-clip-path: inset(0 0 0 0);
              clip-path: inset(0 0 0 0);
}
}
@-webkit-keyframes text-revealer {
0%,
    50% {
      transform-origin: 0 50%;
}
60%,
    100% {
      transform-origin: 100% 50%;
}
60% {
      transform: scaleX(1);
}
100% {
      transform: scaleX(0);
}
}
@keyframes text-revealer {
0%,
    50% {
      transform-origin: 0 50%;
}
60%,
    100% {
      transform-origin: 100% 50%;
}
60% {
      transform: scaleX(1);
}
100% {
      transform: scaleX(0);
}
}